var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","flat":"","color":"black","height":_vm.heightAppbar}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"border-bottom":"1px solid var(--v-c-grey-base)"},style:(("height: " + _vm.heightMainAppbar + "px")),attrs:{"cols":"12"}},[_c('v-container',{staticClass:"py-3"},[(_vm.$vuetify.breakpoint.width >= 1280)?_c('v-row',{attrs:{"no-gutters":"","justify":"space-between","align":"center"}},[_c('div',{staticClass:"d-flex align-center gap-10"},[_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.goToHome()}}},[_c('v-img',{staticClass:"cursor-pointer",attrs:{"src":require("@/assets/images/thinkin-logo-text.svg"),"contain":"","max-width":"100"},on:{"click":function($event){return _vm.$router.replace('/')}}})],1),_c('v-row',{staticClass:"gap-4",attrs:{"no-gutters":""}},[(_vm.wallet.isLogin)?_c('div',{staticClass:"text-body4 cursor-pointer d-flex align-center gap-2",class:_vm.path.includes("/community") ? 'white--text font-weight-bold' : 'primary-lighten2--text',on:{"click":function($event){return _vm.$router.push('/community')}}},[_c('v-img',{attrs:{"src":require(("@/assets/icons/navbar/navbar-dashboard" + (_vm.path.includes("/community") ? '--active' : '') + ".svg")),"max-width":"32"}}),_vm._v(" DASHBOARD ")],1):_vm._e(),_c('wallet-connect-guard',[_c('div',{staticClass:"text-body4 cursor-pointer d-flex align-center gap-2",class:_vm.path.includes('earn') ? 'white--text font-weight-bold' : 'primary-lighten2--text',on:{"click":function($event){return _vm.$router.push('/earn/reward')}}},[_c('v-img',{attrs:{"src":require('@/assets/icons/navbar/navbar-earn.svg'),"max-width":"32"}}),_vm._v(" EARN ")],1)]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-body4 cursor-pointer d-flex align-center gap-2",class:_vm.path.includes('market') ? 'white--text font-weight-bold' : 'primary-lighten2--text'},'div',attrs,false),on),[_c('v-img',{attrs:{"src":require('@/assets/icons/navbar/navbar-market.svg'),"max-width":"32"}}),_vm._v(" MARKET ")],1)]}}],null,false,997315488)},[_c('span',[_vm._v("Coming soon")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-body4 cursor-pointer d-flex align-center gap-2",class:_vm.path.includes('alpha-lounge') ? 'white--text font-weight-bold' : 'primary-lighten2--text'},'div',attrs,false),on),[_c('v-img',{attrs:{"src":require('@/assets/icons/navbar/navbar-alpha.svg'),"max-width":"32"}}),_vm._v(" ALPHA ")],1)]}}],null,false,1956569863)},[_c('span',[_vm._v("Coming soon")])])],1)],1),_c('div',{staticClass:"d-flex align-center gap-4"},[_c('notification'),_c('router-link',{attrs:{"to":"/community/discovery"}},[_c('v-img',{staticClass:"cursor-pointer",attrs:{"src":require("@/assets/icons/discover-icon.svg"),"width":"32","contain":""}})],1),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":"/search/input"}},[_c('v-sheet',{staticClass:"d-flex align-center justify-space-between px-4",attrs:{"height":"50","width":"200","rounded":"lg"}},[_c('div',{staticClass:"primary--text text--lighten-2"},[_vm._v("Search")]),_c('v-icon',{attrs:{"color":"primary lighten-2"}},[_vm._v("mdi-magnify")])],1)],1),_c('connect-wallet')],1)]):_c('v-row',{attrs:{"no-gutters":"","justify":"space-between","align":"center"}},[_c('div',{staticClass:"text-body4 cursor-pointer d-flex align-center gap-2",on:{"click":function($event){return _vm.drawerStatus()}}},[_c('v-img',{attrs:{"src":require("@/assets/icons/menu.svg"),"max-width":"32"}})],1),_c('wallet-connect-guard',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-body4 cursor-pointer d-flex align-center gap-2",class:_vm.path.includes('earn') ? 'white--text font-weight-bold' : 'primary-lighten2--text',on:{"click":function($event){return _vm.$router.push('/earn/reward')}}},'div',attrs,false),on),[_c('v-img',{attrs:{"src":require('@/assets/icons/navbar/navbar-earn.svg'),"max-width":"32"}})],1)]}}])},[_c('span',[_vm._v("EARN")])])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-body4 cursor-pointer d-flex align-center gap-2",class:_vm.path.includes('market') ? 'white--text font-weight-bold' : 'primary-lighten2--text'},'div',attrs,false),on),[_c('v-img',{attrs:{"src":require('@/assets/icons/navbar/navbar-market.svg'),"max-width":"32"}})],1)]}}])},[_c('span',[_vm._v("MARKET")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"src":require(("@/assets/icons/navbar/navbar-alpha" + (_vm.path.includes('alpha-lounge') ? '--active' : '') + ".svg")),"max-width":"32"}},'v-img',attrs,false),on))]}}])},[_c('span',[_vm._v("ALPHA")])]),_c('notification',{attrs:{"mobile":true}}),_c('router-link',{attrs:{"to":"/search/input"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var isActive = ref.isActive;
return [_c('router-link',{attrs:{"to":"/search/input"}},[_c('v-img',{attrs:{"src":require(("@/assets/icons/navbar/navbar-search" + (isActive ? '--active' : '') + ".svg")),"max-width":"32"}})],1)]}}])})],1)],1)],1),(_vm.showSubMenuCommuninty)?_c('v-col',{staticClass:"py-4",staticStyle:{"border-bottom":"1px solid var(--v-c-grey-base)"},style:(("height: " + _vm.heightSubAppBar + "px")),attrs:{"cols":"12"}},[_c('v-container',{staticClass:"d-flex text-body4 gap-12"},[_c('router-link',{staticClass:"text-decoration-none white--text",class:_vm.routeName === 'My Page' && 'font-weight-bold',attrs:{"to":"/community/my-page"}},[_vm._v(" My Feed ")]),_c('router-link',{staticClass:"text-decoration-none white--text",class:_vm.routeName === 'Review' && 'font-weight-bold',attrs:{"to":"/community/review"}},[_vm._v(" Review ")]),_c('router-link',{staticClass:"text-decoration-none white--text",class:_vm.routeName === 'Discovery' && 'font-weight-bold',attrs:{"to":"/community/discovery"}},[_vm._v(" Discover ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }