import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { getPostStatsNumberAfterSync } from '@/helper/utils'
import { apiService } from '@/services/api-services'
import { dispatcher, PostActionModel } from '@/stores/dispatcher'
import { walletStore } from '@/stores/wallet-store'
import { action, computed, observable, reaction, toJS, when } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export class OwnerSectionController {
  private _unsubcrible = new Subject()
  @observable summaryRewardData: any[] = []
  private _disposers: any[]

  constructor() {
    dispatcher.$postChanged.pipe(takeUntil(this._unsubcrible)).subscribe((postAction) => {
      this.syncPostAction(postAction)
    })
    this._disposers = [
      reaction(
        () => walletStore.jwt,
        (profile) => {
          if (profile) this.getRewardSummaryData()
        },
        { fireImmediately: true }
      ),
    ]
  }

  destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
  }

  @action syncPostAction(postAction: PostActionModel) {
    walletStore.changeUserProfile({
      totalFollowers: this.totalFollowers,
      totalPosts: getPostStatsNumberAfterSync(this.userProfile?.totalPosts, postAction.type),
    })
  }

  @asyncAction *getRewardSummaryData() {
    try {
      const res = yield apiService.earningActions.getEarnDataSummary()
      console.log(res.data)
      if (res.data) {
        this.summaryRewardData = res.data
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @computed get userProfile() {
    return walletStore.userProfile
  }

  @computed get avatar() {
    return this.userProfile?.avatar.url
  }

  @computed get username() {
    return this.userProfile?.username
  }

  @computed get totalFollowings() {
    return this.userProfile?.totalFollowings
  }

  @computed get totalFollowers() {
    return this.userProfile?.totalFollowers
  }

  @computed get totalPosts() {
    return this.userProfile?.totalPosts
  }

  @computed get totalPostReward() {
    const postData = this.summaryRewardData.find((item) => item.type === 'post')
    return postData?.totalReward || 0
  }
  @computed get totalUpvoteReward() {
    const postData = this.summaryRewardData.find((item) => item.type === 'upvote')
    return postData?.totalReward || 0
  }
  @computed get totalCommentReward() {
    const commentData = this.summaryRewardData.find((item) => item.type === 'comment')
    return commentData?.totalReward || 0
  }

  @computed get totalReward() {
    return this.totalPostReward + this.totalCommentReward + this.totalUpvoteReward
  }
}

export const communityOwnerBoardController = new OwnerSectionController()
